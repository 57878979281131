<template>
   <div class="customer">
      <!-- 搜索栏 -->
      <div ref="search" class="search">
         <el-card class="box-card" shadow="never" :body-style="{padding:'10px'}">
            <el-form :inline="true" :model="searchForm" label-width="80px">
               <search-open-btn :searchShow="searchShow" @searchOpen="searchOpen"></search-open-btn>
               <el-form-item label="客户类型">
                  <el-select v-model="searchForm.sysCiType" :size="$store.state.size" placeholder="客户类型" clearable>
                     <el-option v-for="(item, index) of this.customerType" :key="index" :label="item.remarks" :value="item.code"></el-option>
                  </el-select>
               </el-form-item>
               <el-form-item label="编码">
                  <el-input v-model="searchForm.sysCiCode" :size="$store.state.size" placeholder="编码" clearable @keyup.enter.native="searchSubmit(true)"></el-input>
               </el-form-item>
               <el-form-item label="全称">
                  <el-input v-model="searchForm.sysCiFullName" :size="$store.state.size" placeholder="全称" clearable @keyup.enter.native="searchSubmit(true)"></el-input>
               </el-form-item>
               <el-form-item label="简称">
                  <el-input v-model="searchForm.sysCiShortName" :size="$store.state.size" placeholder="简称" clearable @keyup.enter.native="searchSubmit(true)"></el-input>
               </el-form-item>
               <el-form-item label="简码">
                  <el-input v-model="searchForm.sysCiShortCode" :size="$store.state.size" placeholder="简码" clearable @keyup.enter.native="searchSubmit(true)"></el-input>
               </el-form-item>
               <el-form-item label="地址" v-show="searchShow">
                  <el-input v-model="searchForm.sysCiAddress" :size="$store.state.size" placeholder="地址" clearable @keyup.enter.native="searchSubmit(true)"></el-input>
               </el-form-item>
               <el-form-item label="联系人" v-show="searchShow">
                  <el-input v-model="searchForm.sysCiContacts" :size="$store.state.size" placeholder="联系人" clearable @keyup.enter.native="searchSubmit(true)"></el-input>
               </el-form-item>
               <el-form-item label="联系电话" v-show="searchShow">
                  <el-input v-model="searchForm.sysCiTel" :size="$store.state.size" placeholder="电话" clearable @keyup.enter.native="searchSubmit(true)"></el-input>
               </el-form-item>
            </el-form>
            <el-divider></el-divider>
            <el-button v-if="hasPermission('2-1-a')" type="primary" :size="$store.state.size" @click="searchSubmit(true)" icon="el-icon-search">查询</el-button>
            <el-button v-if="hasPermission('2-1-b')" type="default" plain :size="$store.state.size" @click="resetSubmit" icon="el-icon-delete">清空</el-button>
            <el-button v-if="hasPermission('2-1-c')" type="primary" :size="$store.state.size" @click="saveOpen" icon="el-icon-circle-plus-outline">新增</el-button>
         </el-card>
      </div>
      <!-- 主体表格 -->
      <el-table :data="tableData" :height="tableHeight" v-loading="tableLoading" @sort-change="sortChange" border :size="this.$common.tableLineHeight()" :header-cell-style="{background:'#f5f7fa',color:'#515a6e', fontWeight:'900'}" highlight-current-row @current-change="handlerTableCurrent" style="width: 100%; margin-top: 10px">
         <el-table-column prop="sysCiTypeStr" align="center" label="客户类型">
         </el-table-column>
         <el-table-column prop="sysCiFullName" align="center" label="全称">
         </el-table-column>
         <el-table-column prop="sysCiShortName" align="center" label="简称">
         </el-table-column>
         <el-table-column prop="sysCiShortCode" align="center" label="简码">
         </el-table-column>
         <el-table-column prop="sysCiAddress" align="center" label="地址">
         </el-table-column>
         <el-table-column prop="sysCiContacts" align="center" label="联系人">
         </el-table-column>
         <el-table-column prop="sysCiTel" align="center" label="电话">
         </el-table-column>
         <el-table-column align="center" label="操作" width="300">
            <template slot-scope="scope">
               <el-button v-if="hasPermission('2-1-d')" type="text" size="small" @click="seeOpen(scope.row)" icon="el-icon-document">查看</el-button>
               <el-button v-if="hasPermission('2-1-e')" type="text" size="small" @click="editOpen(scope.row)" icon="el-icon-edit-outline">编辑</el-button>
               <el-button v-if="hasPermission('2-1-g') && scope.row.sysCiTypeStr == '供应商'" type="text" size="small" @click="seeSupplierManufacturer(scope.row)" icon="el-icon-view">所属制造商</el-button>
               <!-- <el-button v-if="hasPermission('2-1-h') && scope.row.sysCiTypeStr == '供应商'" type="text" size="small" @click="openAddSupplierManufacturer(scope.row)" icon="el-icon-circle-plus-outline">添加制造商</el-button> -->
               <el-button v-if="hasPermission('2-1-f')" type="text" size="small" @click="delOpen(scope.row)" icon="el-icon-delete">删除</el-button>
            </template>
         </el-table-column>
      </el-table>

      <!-- 分页组件 -->
      <pagination :page-size='currentPageSize' :current-page="currentPage" :total="total" @size-change="handleSizeChange" @current-change="handleCurrentChange">
      </pagination>

      <!--    保存模态框-->
      <assembly-dialog :title="dialogTitle" :visible.sync="dialogVisibleSave" width="30%" @before-close="handleClose">
         <el-form :model="saveForm" :rules="saveRules" ref="saveForm" label-width="80px">
            <el-form-item label="客户类型" prop="sysCiType">
               <el-select :disabled='dialogTitle == "编辑"' v-model="saveForm.sysCiType" :size="$store.state.size" placeholder="客户类型" clearable @change="changeCustomerType">
                  <el-option v-for="(item, index) of this.customerType" :key="index" :label="item.remarks" :value="item.code"></el-option>
               </el-select>
            </el-form-item>
            <el-form-item label="制造商" prop="manufacturerIds" v-if="saveForm.sysCiType === 2 && dialogTitle == '新增'">
               <!-- v-if="saveForm.goodsPiCategoryId == 3"-->
               <el-select v-model="saveForm.manufacturerIds" placeholder="请选择" clearable multiple>
                  <!-- @click.native="machiningInfo('saveForm')"-->
                  <el-option v-for="e in manufacturerList" :key="e.sysCiId" :label="e.sysCiShortName" :value="e.sysCiId"></el-option>
               </el-select>
            </el-form-item>
            <el-form-item label="编码" prop="sysCiCode" v-if="showCode">
               <el-input v-model="saveForm.sysCiCode" :size="$store.state.size" readonly placeholder="编码" clearable></el-input>
            </el-form-item>
            <el-form-item label="全称" prop="sysCiFullName">
               <el-input :loading='true' v-model="saveForm.sysCiFullName" :size="$store.state.size" placeholder="全称" clearable></el-input>
            </el-form-item>
            <el-form-item label="简称" prop="sysCiShortName">
               <el-input v-model="saveForm.sysCiShortName" :size="$store.state.size" placeholder="简称" clearable></el-input>
            </el-form-item>
            <el-form-item label="简码">
               <el-input v-model="saveForm.sysCiShortCode" :size="$store.state.size" placeholder="简码" clearable disabled></el-input>
            </el-form-item>
            <el-form-item label="地址" prop="sysCiAddress">
               <el-input v-model="saveForm.sysCiAddress" :size="$store.state.size" placeholder="地址" clearable></el-input>
            </el-form-item>
            <el-form-item label="联系人" prop="sysCiContacts">
               <el-input v-model="saveForm.sysCiContacts" :size="$store.state.size" placeholder="联系人" clearable></el-input>
            </el-form-item>
            <el-form-item label="联系电话" prop="sysCiTel">
               <el-input v-model="saveForm.sysCiTel" :size="$store.state.size" placeholder="电话" clearable></el-input>
            </el-form-item>
         </el-form>
         <span slot="footer" class="dialog-footer">
            <el-button :size="$store.state.size" @click="clearForm('saveForm')">清 空</el-button>
            <el-button :size="$store.state.size" type="primary" @click="saveSubmit('saveForm')">确 定</el-button>
         </span>
      </assembly-dialog>

      <!--    查看模态框-->
      <assembly-dialog title="查看" :visible.sync="dialogVisibleSee" width="30%" @before-close="seeClose">
         <el-descriptions class="margin-top" :column="1" :size="$store.state.size" border>
            <el-descriptions-item>
               <template slot="label">
                  客户类型
               </template>
               {{seeForm.sysCiTypeStr}}
            </el-descriptions-item>
            <!-- v-if="seeForm.sysCiTypeStr=='制造商'" -->
            <el-descriptions-item>
               <template slot="label">
                  编码
               </template>
               {{seeForm.sysCiCode}}
            </el-descriptions-item>
            <el-descriptions-item>
               <template slot="label">
                  全称
               </template>
               {{seeForm.sysCiFullName}}
            </el-descriptions-item>
            <el-descriptions-item>
               <template slot="label">
                  简称
               </template>
               {{seeForm.sysCiShortName}}
            </el-descriptions-item>
            <el-descriptions-item>
               <template slot="label">
                  简码
               </template>
               {{seeForm.sysCiShortCode}}
            </el-descriptions-item>
            <el-descriptions-item>
               <template slot="label">
                  地址
               </template>
               {{seeForm.sysCiAddress}}
            </el-descriptions-item>
            <el-descriptions-item>
               <template slot="label">
                  联系人
               </template>
               {{seeForm.sysCiContacts}}
            </el-descriptions-item>
            <el-descriptions-item>
               <template slot="label">
                  联系电话
               </template>
               {{seeForm.sysCiTel}}
            </el-descriptions-item>
         </el-descriptions>
         <span slot="footer" class="dialog-footer">
            <el-button :size="$store.state.size" @click="seeClose">关 闭</el-button>
         </span>
      </assembly-dialog>
      <!--  查看所属制造商模态框  -->
      <assembly-dialog title="所属制造商列表" :visible.sync="manufacturerPage" width="60%" @before-close="manufacturerPage = false">
         <el-button type='primary' v-if="hasPermission('2-1-h')" size="small" @click="openAddSupplierManufacturer(ZZSRow)" icon="el-icon-circle-plus-outline">添加制造商</el-button>
         <el-table :data="supplierManufacturerList" :height="tableHeight" v-loading="tableLoading" @sort-change="sortChange" border :size="this.$common.tableLineHeight()" :header-cell-style="{background:'#f5f7fa',color:'#515a6e', fontWeight:'900'}" style="width: 100%; margin-top: 10px">
            <el-table-column prop="sysCiFullName" align="center" label="制造商全称">
            </el-table-column>
            <el-table-column prop="sysCiShortName" align="center" label="制造商简称">
            </el-table-column>
            <el-table-column prop="sysCiShortCode" align="center" label="制造商简码">
            </el-table-column>
            <el-table-column prop="sysCiContacts" align="center" label="联系人">
            </el-table-column>
            <el-table-column prop="sysCiTel" align="center" label="联系电话">
            </el-table-column>
            <el-table-column align="center" label="操作" width="230">
               <template slot-scope="scope">
                  <el-button v-if="hasPermission('2-1-i')" type="text" size="small" @click="delSupplierManufacturer(scope.row)" icon="el-icon-delete">删除</el-button>
               </template>
            </el-table-column>
         </el-table>
         <pagination :current-page="supplierManufacturerPage" :total="supplierManufacturerTotal" @size-change="handleSize" @current-change="handleCurrent">
         </pagination>
      </assembly-dialog>

      <!-- 所属制造商添加编辑 -->
      <assembly-dialog title="添加所属制造商" :visible.sync="dialogVisibleAddSupplierManufacturer" width="30%" @before-close="addSupplierManufacturerClose">
         <el-form :model="addSupplierManufacturerForm" ref="addSupplierManufacturerForm" label-width="80px">
            <el-form-item label="制造商" prop="sysMsManufacturerIds">
               <!-- v-if="saveForm.goodsPiCategoryId == 3"-->
               <el-select v-model="addSupplierManufacturerForm.sysMsManufacturerIds" placeholder="请选择" clearable multiple>
                  <!-- @click.native="machiningInfo('saveForm')"-->
                  <el-option v-for="e in manufacturerList" :key="e.sysCiId" :label="e.sysCiShortName" :value="e.sysCiId"></el-option>
               </el-select>
            </el-form-item>
         </el-form>
         <span slot="footer" class="dialog-footer">
            <el-button :size="$store.state.size" @click="clearAddSupplierManufacturerForm()">清 空</el-button>
            <el-button :size="$store.state.size" type="primary" @click="addSupplierManufacturer">确 定</el-button>

         </span>
      </assembly-dialog>
   </div>
</template>

<script>
import searchOpenBtn from "@/components/assembly-search/search-open-btn/index";
import pagination from "@/components/assembly-pagination/index";
import getPinYinUtil from "@/assets/js/get-pinyin-util.js";
import AssemblyDialog from "components/assembly-dialog";

export default {
   name: "customer",
   components: {
      AssemblyDialog,
      searchOpenBtn,
      pagination,
   },
   watch: {
      //监听头部展开收起变化
      "$store.state.isHeader"() {
         this.setTableHeight();
      },
      //监听快速导航展开收起变化
      "$store.state.fastNav"() {
         this.setTableHeight();
      },
      //监听搜索区域变化改变列表
      searchShow() {
         this.setTableHeight();
      },
      "saveForm.sysCiShortName"() {
         let shortCode = getPinYinUtil.ConvertPinyin(
            this.saveForm.sysCiShortName
         );
         this.saveForm.sysCiShortCode = shortCode;
      },
   },
   data() {
      return {
         currentPageSize: 10,
         ZZSRow: "",
         dialogTitle: "新增",
         //搜索区域展开收起开关
         searchShow: false,
         //表格高度
         tableHeight: 0,
         //加载
         tableLoading: false,
         //表格数据
         tableData: [],
         //当前页面
         currentPage: 1,
         //总数
         total: 0,
         customerType: [],
         //搜索表单
         searchForm: {
            sysCiType: "",
            sysCiFullName: "",
            sysCiShortName: "",
            sysCiShortCode: "",
            sysCiAddress: "",
            sysCiContacts: "",
            sysCiCode: "",
            sysCiTel: "",
            orderByColumn: "",
            order: "",
            page: 1,
            pageSize: this.$store.state.initPageSize,
         },
         //保存模态框开关
         dialogVisibleSave: false,
         //保存表单
         saveForm: {
            sysCiId: "",
            sysCiType: "",
            sysCiFullName: "",
            sysCiShortName: "",
            sysCiShortCode: "",
            sysCiAddress: "",
            sysCiContacts: "",
            sysCiTel: "",
            sysCiCode: "",
            manufacturerIds: "",
         },
         //保存表单验证
         saveRules: {
            sysCiType: [
               { required: true, message: "请选择客户类型", trigger: "change" },
            ],
            sysCiFullName: [
               {
                  required: true,
                  validator: this.$verification.validateSysCiFullName,
                  trigger: "blur",
               },
            ],
            sysCiShortName: [
               {
                  required: true,
                  validator: this.$verification.validateSysCiShortName,
                  trigger: "blur",
               },
            ],
            sysCiAddress: [
               {
                  required: true,
                  validator: this.$verification.validateSysCiAddress,
                  trigger: "blur",
               },
            ],
            sysCiContacts: [
               {
                  required: true,
                  validator: this.$verification.validateSysCiContacts,
                  trigger: "blur",
               },
            ],
            sysCiTel: [
               {
                  required: true,
                  validator: this.$verification.validateSysCiTel,
                  trigger: "blur",
               },
            ],
            manufacturerIds: [
               { required: true, trigger: "blur", message: "请选择制造商" },
            ],
         },
         //查看模态框开关
         dialogVisibleSee: false,
         seeForm: {
            sysCiCode: "",
            sysCiTypeStr: "",
            sysCiFullName: "",
            sysCiShortName: "",
            sysCiShortCode: "",
            sysCiAddress: "",
            sysCiContacts: "",
            sysCiTel: "",
         },
         //  显示客户类型编码
         showCode: false,
         //制造商列表
         manufacturerList: [],
         //查看所属制造商模态框
         manufacturerPage: false,
         //供应商-制造商列表
         supplierManufacturerList: [],
         //供应商-制造商总条数
         supplierManufacturerTotal: 0,
         //供应商-制造商当前页
         supplierManufacturerPage: 1,
         //供应商-制造商搜索表单
         searchsupplierManufacturerForm: {
            sysMsSupplierId: "",
            page: 1,
            pageSize: this.$store.state.initPageSize,
         },
         //添加所属制造商模态框
         dialogVisibleAddSupplierManufacturer: false,
         //添加所属制造商表单
         addSupplierManufacturerForm: {
            sysMsManufacturerIds: "",
            sysMsSupplierId: "",
         },
         //当前选中行
         currentRow: null,
      };
   },
   mounted() {
      //初始化计算表格区域高度
      this.setTableHeight();
      this.getCustomerType();
      this.currentPageSize = this.$store.state.initPageSize;
      this.searchSubmit();
   },
   // activated(){
   //   this.currentPageSize = this.$store.state.initPageSize;
   //   this.searchForm.pageSize = this.currentPageSize;
   //   this.searchSubmit();
   // },
   methods: {
      //表格选中行
      handlerTableCurrent(row) {
         this.currentRow = row;
      },

      //判断权限是否存在
      hasPermission(item) {
         return this.$common.hasPermission(item);
      },
      /****表格高度计算 start********************************************************************************/
      //计算表格区域高度
      setTableHeight() {
         this.$nextTick(() => {
            this.tableHeight = this.$common.getHeight(
               this.$refs.search.clientHeight
            );
            window.onresize = () => {
               return (() => {
                  this.tableHeight = this.$common.getHeight(
                     this.$refs.search.clientHeight
                  );
               })();
            };
         });
      },
      /****表格高度计算 end********************************************************************************/
      /****搜素区域 start********************************************************************************/
      //搜索展开收起事件
      searchOpen(searchShow) {
         this.searchShow = searchShow;
      },
      //搜索表单提交
      searchSubmit(flag) {
         if (flag) {
            this.searchForm.page = 1;
            this.currentPage = 1;
            this.searchForm.pageSize = this.currentPageSize;
         }
         this.tableLoading = true;
         this.$server
            .getCustomerInfo(this.searchForm)
            .then((res) => {
               this.tableLoading = false;
               if (res.ok) {
                  this.tableData = res.data.records;
                  this.total = res.data.total;
               }
               // console.log("hasPermission('2-1-g') && seeForm.sysCiTypeStr == '供应商'",hasPermission('2-1-g'))
               // console.log("sysCiTypeStr",this.seeForm.sysCiTypeStr)
            })
            .catch((e) => {
               this.tableLoading = false;
               console.log(e);
            });
      },
      //搜索表单重置
      resetSubmit() {
         this.$common.clearFormValue(this.searchForm);
         this.searchForm.page = 1;
         this.searchForm.pageSize = this.currentPageSize;
         this.searchSubmit();
         this.currentPage = 1;
      },
      /****搜素区域 end********************************************************************************/
      /****保存区域 start********************************************************************************/
      //保存表单提交
      saveOpen() {
         //制造商列表
         this.dialogTitle = "新增";
         this.manufacturer();
         this.dialogVisibleSave = true;
      },
      //模态框关闭前回调
      handleClose() {
         this.showCode = false;
         this.dialogVisibleSave = false;
         this.clearForm("saveForm");
      },
      //保存提交
      saveSubmit(formName) {
         this.$refs[formName].validate((valid) => {
            if (valid) {
               if (this.saveForm.manufacturerIds) {
                  this.saveForm.manufacturerIds =
                     this.saveForm.manufacturerIds.toString();
               }
               this.$server
                  .saveCustomerInfo(this.saveForm)
                  .then((res) => {
                     if (res.ok) {
                        this._resetPage();
                        this.$message.success(res.msg);
                        this.searchSubmit();
                     } else {
                        this.$message.error(res.msg);
                     }
                     this.dialogVisibleSave = false;
                     this.showCode = false;
                     this.clearForm("saveForm");
                  })
                  .catch((e) => {
                     console.log(e);
                  });
            } else {
               console.log("error submit!!");
               return false;
            }
         });
      },
      //清空表单
      clearForm(formName) {
         let sysCiType = "";
         if (this.dialogTitle == "编辑") {
            sysCiType = this.saveForm.sysCiType;
         }
         this.$refs[formName].resetFields();
         this.showCode = false;
         if (this.dialogTitle == "编辑") {
            this.saveForm.sysCiType = sysCiType;
         }
         // this.$common.clearFormValue(this.saveForm)
      },
      //编辑模态框开启
      editOpen(row) {
         this.dialogTitle = "编辑";
         this.dialogVisibleSave = true;
         this.$nextTick(() => {
            this.saveForm = JSON.parse(JSON.stringify(row));
         });
      },
      //制造商列表
      manufacturer() {
         let user = JSON.parse(sessionStorage.getItem("userInfo"));
         this.searchForm.userid = user.sysUiId;
         this.$server
            .getList({ sysCiType: 1, userid: user.sysUiId })
            .then((res) => {
               if (res.ok) {
                  // console.log('制造商列表请求数据', JSON.stringify(res.data))
                  this.manufacturerList = res.data;
               }
            })
            .catch((e) => {
               console.log(e);
            });
      },
      /****保存区域 end********************************************************************************/
      /****查看区域 start********************************************************************************/
      seeOpen(row) {
         this.seeForm = row;
         this.dialogVisibleSee = true;
      },
      seeClose() {
         this.dialogVisibleSee = false;
      },
      /****查看区域 end********************************************************************************/
      /****删除区域 start********************************************************************************/
      delOpen(row) {
         this.$confirm("确认删除该条数据, 是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
         })
            .then(() => {
               this.$server
                  .removeCustomerInfo({ id: row.sysCiId })
                  .then((res) => {
                     if (res.ok) {
                        this.$message.success(res.msg);
                        this.searchSubmit();
                     } else {
                        this.$message.error(res.msg);
                     }
                  })
                  .catch((e) => {
                     console.log(e);
                  });
            })
            .catch(() => {});
      },
      /****删除区域 end********************************************************************************/
      /****查看供应商-制造商区域 start*******************************************************************/
      seeSupplierManufacturer(row) {
         console.log(row, "rrrrrrrrrrrrrr");
         this.ZZSRow = row;
         this.manufacturerPage = true;
         this.tableLoading = true;
         // if(!this.searchsupplierManufacturerForm.sysMsSupplierId){
         this.searchsupplierManufacturerForm.sysMsSupplierId =
            row.sysCiId || row.sysMsSupplierId;
         // }
         this.$server
            .supplierManufacturerList(this.searchsupplierManufacturerForm)
            .then((res) => {
               this.tableLoading = false;
               if (res.ok) {
                  this.supplierManufacturerList = res.data.list;
                  console.log(
                     "supplierManufacturerList",
                     this.supplierManufacturerList
                  );
                  this.supplierManufacturerTotal = res.data.total;
               }
            })
            .catch((e) => {
               this.tableLoading = false;
               console.log(e);
            });
      },
      handleSize(val) {
         this.searchsupplierManufacturerForm.pageSize = val;
         this.seeSupplierManufacturer(
            this.searchsupplierManufacturerForm,
            true
         );
      },
      handleCurrent(val) {
         this.currentPage = val;
         console.log(this.totalsubCurrentPage);
         this.searchsupplierManufacturerForm.page = val;
         this.seeSupplierManufacturer(
            this.searchsupplierManufacturerForm,
            true
         );
      },
      /****查看供应商-制造商区域 end*********************************************************************/
      /****删除供应商-制造商区域 start*******************************************************************/
      delSupplierManufacturer(row) {
         // YF TODO 2022-11-10 15:03:22
         this.$confirm("确认删除该条数据, 是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
         })
            .then(() => {
               this.$server
                  .delSupplierManufacturer({ id: row.sysMsId })
                  .then((res) => {
                     if (res.ok) {
                        this._getList();
                        this.$message.success(res.msg);
                        // this.seeSupplierManufacturer()
                     } else {
                        this.$message.error(res.msg);
                        this._getList();
                     }
                  })
                  .catch((e) => {
                     console.log(e);
                     this._getList();
                  });
            })
            .catch(() => {});
      },
      /****删除供应商-制造商区域 end*********************************************************************/
      /****添加供应商-制造商区域 start*******************************************************************/
      /**
       * 打开模态框
       * @param row
       */
      openAddSupplierManufacturer(row) {
         // console.log(row,'rrrrrrrrrrrr')
         // return
         this.addSupplierManufacturerForm.sysMsSupplierId = row.sysCiId;
         //制造商列表
         this.manufacturer();
         this.dialogVisibleAddSupplierManufacturer = true;
      },
      /**
       * 关闭模态框
       */
      addSupplierManufacturerClose() {
         this.clearAddSupplierManufacturerForm();
         this.dialogVisibleAddSupplierManufacturer = false;
      },
      /**
       * 清空表单
       */
      clearAddSupplierManufacturerForm() {
         this.addSupplierManufacturerForm.sysMsManufacturerIds = "";
      },
      /**
       * 保存供应商-制造商
       * @param row
       */
      addSupplierManufacturer() {
         // console.log(this.addSupplierManufacturerForm.sysMsManufacturerIds)
         // return
         if (!this.addSupplierManufacturerForm.sysMsManufacturerIds.length) {
            this.dialogVisibleAddSupplierManufacturer = false;
            return;
         }
         const row = this.currentRow;
         this.addSupplierManufacturerForm.sysMsSupplierId = row.sysCiId;
         if (this.addSupplierManufacturerForm.sysMsManufacturerIds) {
            this.addSupplierManufacturerForm.sysMsManufacturerIds =
               this.addSupplierManufacturerForm.sysMsManufacturerIds.toString();
         }
         this.$server
            .addSupplierManufacturer(this.addSupplierManufacturerForm)
            .then((res) => {
               if (res.ok) {
                  this.$message.success(res.msg);
                  // YF TODO 2022-11-10 14:32:35
                  this._getList();
               } else {
                  this.$message.error(res.msg);
                  this._getList();
               }
               this.addSupplierManufacturerClose();
               this.addSupplierManufacturerForm.sysMsSupplierId = "";
               this.searchsupplierManufacturerForm.sysMsSupplierId =
                  row.sysCiId;
               this.manufacturerPage = true;
            })
            .catch((e) => {
               this._getList();
            });
      },
      _getList() {
         this.$server
            .supplierManufacturerList(this.searchsupplierManufacturerForm)
            .then((res) => {
               this.tableLoading = false;
               if (res.ok) {
                  this.supplierManufacturerList = res.data.list;
                  this.supplierManufacturerTotal = res.data.total;
               } else {
               }
            })
            .catch((_) => {});
      },
      /****添加供应商-制造商区域 end*********************************************************************/
      //获取制造商编码
      changeCustomerType(row) {
         console.log("row :>> ", row);
         console.log("获取制造商编码", JSON.stringify(row));
         // if(row!=1){
         //   this.showCode = false
         //   this.saveForm.sysCiCode = ''
         //   return
         // }
         let data = {
            sysCiType: row,
         };
         this.showCode = true;
         this.$server
            .getCustomerCode(data)
            .then((res) => {
               console.log("res :>> ", res);
               if (res.ok) {
                  this.saveForm.sysCiCode = res.msg;
               }
            })
            .catch((e) => {
               console.log(e);
            });
      },
      handleSizeChange(val) {
         this.currentPage = 1;
         this.currentPageSize = val;
         this.searchForm.pageSize = val;
         this.searchForm.page = 1;
         this.searchSubmit();
      },
      handleCurrentChange(val) {
         this.currentPage = val;
         this.searchForm.page = val;
         this.searchSubmit();
      },

      _resetPage() {
         (this.currentPage = 1), (this.searchForm.page = 1);
      },
      //排序
      sortChange(row) {
         console.log("排序排序排序", JSON.stringify(row));
         if (row.prop == "sysCiTypeStr") {
            this.searchForm.orderByColumn = "sysCiId";
         } else {
            this.searchForm.orderByColumn = row.prop;
         }
         if (row.order == "ascending") {
            this.searchForm.order = "asc";
         } else if (row.order == "descending") {
            this.searchForm.order = "desc";
         } else {
            this.searchForm.order = "";
         }
         this.searchSubmit();
         this.searchForm.page = 1;
      },
      getCustomerType() {
         this.$server
            .getCustomerType()
            .then((res) => {
               if (res.ok) {
                  this.customerType = res.data;
               }
            })
            .catch((e) => {
               console.log(e);
            });
      },
      onSubmit() {
         console.log("submit!");
      },
   },

   beforeRouteEnter(to, from, next) {
      // const flag = this.$store.state.currentRouterState
      next((vm) => {
         // alert('我是 next 函数')
      });
   },
   beforeRouteLeave(to, from, next) {
      // alert('我要离开这个页面了')
      next();
   },
};
</script>

<style lang="scss">
.customer {
   .search {
      //表单每行底部外边距设置
      .el-form-item {
         margin-bottom: 10px;
      }

      .el-select {
         width: 200px;
      }

      .el-input {
         width: 200px;
      }

      //分割线外边距设置
      .el-divider--horizontal {
         margin: 0 0 10px 0;
      }
   }

   .el-dialog {
      .el-select {
         width: 100%;
      }
   }
}
</style>

<style scoped>
::v-deep .el-dialog {
   display: flex;
   flex-direction: column;
   margin: 0 !important;
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
   max-height: calc(100% - 30px);
   max-width: calc(100% - 30px);
}
</style>
